import * as React from "react";
import { FeatureModuleVsType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleVsP.type";
import { ComparisonTablePType } from "../../assets/Types/AcfGroupTypes/ComparisonTableP.type";
import VsStepsList from "../../components/VsStepsList/VsStepsList";

import styles from "./FeaturesModuleVsPContainer.module.scss";

type FeaturesModuleVsPContainerProps = {
  featureModuleVsP: FeatureModuleVsType;
  comparisonTableP: ComparisonTablePType | null;
};

const FeaturesModuleVsPContainer: React.FC<FeaturesModuleVsPContainerProps> = ({
  featureModuleVsP,
  comparisonTableP,
}) => {
  const { vsStepsList, vsStepsTitle } = featureModuleVsP;

  return (
    <section className={`pad-top--large`}>
      <div className={`text-alignment full-width`}>
        <h2 className={`h1 ${styles.vsTitle}`}>{vsStepsTitle}</h2>
      </div>

      <VsStepsList
        vsStepsList={vsStepsList}
        comparisonTableP={comparisonTableP}
      />
    </section>
  );
};

export default FeaturesModuleVsPContainer;
