import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import HeroPContainer from "../../containers/HeroPContainer/HeroPContainer";
import TextStatsPContainer from "../../containers/TextStatsPContainer/TextStatsPContainer";
import FeaturesModuleVsPContainer from "../../containers/FeaturesModuleVsPContainer/FeaturesModuleVsPContainer";
import VsFaqPContainer from "../../containers/VsFaqPContainer/VsFaqPContainer";
import { HeroPType } from "../../assets/Types/AcfGroupTypes/HeroP.type";
import { TextStatsType } from "../../assets/Types/AcfGroupTypes/TextStatsP.type";
import { FeatureModuleVsType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleVsP.type";
import { ComparisonTablePType } from "../../assets/Types/AcfGroupTypes/ComparisonTableP.type";
import { ContentFaqsPType } from "../../assets/Types/AcfGroupTypes/VsFaqPContainer.type";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import useOnUserIntent from "../../hooks/useOnUserIntent";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      heroP: HeroPType;
      textStatsP: TextStatsType;
      featureModuleVsP: FeatureModuleVsType;
      comparisonTableP: ComparisonTablePType;
      contentFaqsP: ContentFaqsPType;
      userIntent: Queries.WpFAQTemplate_Userintent;
    };
  };
};

const VsPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { wpPage } = data;
  const {
    template: {
      heroP,
      textStatsP,
      featureModuleVsP,
      comparisonTableP,
      contentFaqsP,
      userIntent,
    },
  } = wpPage;

  useOnUserIntent(userIntent);

  const hasTextStats = textStatsP.statsList;
  const hasFaqs =
    contentFaqsP.contentFaqsList?.length > 0 &&
    contentFaqsP.contentFaqsSubcopy &&
    contentFaqsP.contentFaqsTitle &&
    true;

  return (
    <MainLayout hasFooterLargeCta={true}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <HeroPContainer props={heroP} />
      {hasTextStats && <TextStatsPContainer textStatsP={textStatsP} />}
      <FeaturesModuleVsPContainer
        featureModuleVsP={featureModuleVsP}
        comparisonTableP={comparisonTableP}
      />
      <>
        {hasFaqs && (
          <VsFaqPContainer contentFaqsP={contentFaqsP} isVsContent={true} />
        )}
      </>
    </MainLayout>
  );
};

export const GET_VS_TEMPLATE_INFO = graphql`
  query VsTemplateQuery($id: String) {
    wpPage(id: { eq: $id }) {
      id
      ...YoastSEOFragment
      template {
        templateName
        ... on WpVSTemplate {
          ...VsUserIntentFragment
          templateName
          heroP {
            heroTitle
            heroSubcopy
            heroInputPlaceholder
            heroCcText
            heroButtonText
            heroButtonLink
          }
          textStatsP {
            statsList {
              description
              numberValue
            }
          }
          featureModuleVsP {
            vsStepsTitle
            vsStepsList {
              stepNumber
              stepTitle
              stepAssetToggle
              stepContent
              stepImage {
                altText
                localFilePNG: localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1200
                      placeholder: BLURRED
                      formats: [WEBP, JPG, AUTO]
                    )
                  }
                }
                localFileWEBP: localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1200
                      placeholder: BLURRED
                      formats: [WEBP, JPG, AUTO]
                    )
                  }
                }
              }
              stepBadgeColor
              stepVideo {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                }
                title
                uri
                slug
              }
              stepTestimonialAuthor
              stepTestimonialQuote
              stepModuleTitle
              stepModuleContent
              stepModuleBadge
              stepBadge
              stepVideoImage {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                }
              }
            }
          }
          comparisonTableP {
            vsTableHeaders {
              fieldGroupName
              label
            }
            vsTableBody {
              competitorValue
              description
              essentialValue
              fieldGroupName
              professionalValue
              subcopy
              competitorCheck
              essentialCheck
              professionalCheck
            }
          }
          contentFaqsP {
            contentFaqsTitle
            contentFaqsSubcopy
            contentFaqsList {
              ... on WpFaq {
                id
                title
                uri
                faqsC {
                  questionsAnswer
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default VsPage;
