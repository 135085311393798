import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StepListNodeType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleVsP.type";
import { ComparisonTablePType } from "../../assets/Types/AcfGroupTypes/ComparisonTableP.type";
import VsTable from "./VsTable/VsTable";
import VsTestimonialBanner from "./VsTestimonialBanner/VsTestimonialBanner";
import LinkBetweenImages from "../../components/SVGImages/LinkBetweenImages/LinkBetweenImages";
import LinkBetweenImagesFlipped from "../../components/SVGImages/LinkBetweenImagesFlipped/LinkBetweenImagesFlipped";
import { isAppleFamilyDevices } from "../../utils/validations";
import styles from "./VsStepsList.module.scss";

interface VsStepsListProps {
  vsStepsList: Array<StepListNodeType> | null;
  comparisonTableP: ComparisonTablePType | null;
}
const VsStepsList: React.FC<VsStepsListProps> = ({
  vsStepsList,
  comparisonTableP,
}) => {
  const hasTable =
    comparisonTableP.vsTableBody && comparisonTableP.vsTableHeaders;

  return (
    <>
      {vsStepsList?.map((step, index) => {
        const flipped = index % 2 !== 0;
        const hasLinkImage =
          step.stepModuleTitle &&
          step.stepModuleContent &&
          step.stepModuleBadge;
        const hasTestimonial =
          step.stepTestimonialAuthor && step.stepTestimonialQuote;

        const image = getImage(
          isAppleFamilyDevices()
            ? step.stepImage?.localFilePNG
            : step.stepImage?.localFileWEBP
        );

        let stepGraphic;
        switch (step.stepAssetToggle) {
          case "video":
            stepGraphic = (
              <video
                width="100%"
                autoPlay
                playsInline
                muted
                loop
                poster={String(step.stepVideoImage?.mediaItemUrl)}
                className="video"
              >
                <source
                  src={String(step.stepVideo?.mediaItemUrl)}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            );
            break;
          case "image":
            stepGraphic = (
              <GatsbyImage image={image} alt={step.stepImage?.altText || ""} />
            );
            break;
          default:
            stepGraphic = (
              <GatsbyImage image={image} alt={step.stepImage?.altText || ""} />
            );
        }

        return (
          <React.Fragment key={index}>
            <div
              className={`${styles.vsWrapper} ${
                !hasTestimonial && styles.pbZero
              }`}
            >
              <div className={flipped ? styles.vsGridFlipped : styles.vsGrid}>
                <div className={styles.vsCopy}>
                  <p className={styles.vsStepBadge}>{step.stepBadge}</p>
                  <h2 className="h1mini">{step.stepTitle}</h2>
                  <p className={styles.vsStepContent}>{step.stepContent}</p>
                </div>

                <div className={styles.vsImage}>
                  <div
                    className={`${styles.vsStepNumber} ${
                      flipped ? styles.vsStepNumberFlipped : ""
                    }`}
                  >
                    <h2>{step.stepNumber}</h2>
                  </div>
                  {stepGraphic}
                </div>
              </div>

              {hasLinkImage && (
                <>
                  <div
                    className={
                      flipped ? styles.vsVectorFlipped : styles.vsVector
                    }
                  >
                    {flipped ? (
                      <LinkBetweenImagesFlipped />
                    ) : (
                      <LinkBetweenImages />
                    )}
                  </div>
                  <div className={`text-alignment ${styles.vsModule}`}>
                    <p className={`badge ${styles.vsModuleBagde}`}>
                      {step.stepModuleBadge}
                    </p>
                    <h2>{step.stepModuleTitle}</h2>
                    <p className={styles.vsModuleBody}>
                      {step.stepModuleContent}
                    </p>
                  </div>
                </>
              )}

              {hasTable && index === 0 && comparisonTableP && (
                <VsTable comparisonTableP={comparisonTableP} />
              )}
            </div>

            {hasTestimonial && index < 4 && (
              <VsTestimonialBanner
                author={step.stepTestimonialAuthor}
                quote={step.stepTestimonialQuote}
                step={index}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default VsStepsList;
