import React from "react";

const LinkBetweenImages: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={`icon ${className}`}
      width="405"
      height="225"
      viewBox="0 0 405 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="link-between-images"
    >
      <path
        d="M1.10352 1.10254H15.2421"
        stroke="#1371C8"
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
      <path
        d="M8.08594 1.4707C8.08594 235.649 394.709 -17.9039 394.709 213.971"
        stroke="#1371C8"
        strokeWidth="2"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M394.71 225C400.394 225 405.001 220.392 405.001 214.706C405.001 209.021 400.394 204.412 394.71 204.412C389.027 204.412 384.42 209.021 384.42 214.706C384.42 220.392 389.027 225 394.71 225Z"
        fill="#1371C8"
        fillOpacity="0.16"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M394.71 217.647C396.333 217.647 397.65 216.33 397.65 214.706C397.65 213.082 396.333 211.765 394.71 211.765C393.086 211.765 391.77 213.082 391.77 214.706C391.77 216.33 393.086 217.647 394.71 217.647Z"
        fill="white"
        stroke="#1371C8"
        strokeWidth="2"
      ></path>
    </svg>
  );
};

export default LinkBetweenImages;
