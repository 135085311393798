import React from "react";
import styles from "./VsTestimonialBanner.module.scss";

interface VsTestimonialBannerProps {
  quote: string;
  author: string;
  step: number;
}

const VsTestimonialBanner: React.FC<VsTestimonialBannerProps> = ({
  quote,
  author,
  step,
}) => {
  return (
    <div className={styles.testimonialBanner}>
      <div className={styles.testimonialWrapper}>
        <span>&ldquo;{quote}&rdquo;</span>
        <p>— {author}</p>
      </div>
      {step === 0 && (
        <div className={styles.customerSatisfactionContainer}>
          <div className={styles.customerSatisfactionContent}>
            <h2>96%</h2>
            <p>
              practitioner <br /> satisfaction
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VsTestimonialBanner;
