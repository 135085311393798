import React from "react";
import { ComparisonTablePType } from "../../../assets/Types/AcfGroupTypes/ComparisonTableP.type";
import styles from "./VsTable.module.scss";

interface VsTableProps {
  comparisonTableP: ComparisonTablePType | null;
}

const VsTable: React.FC<VsTableProps> = ({ comparisonTableP }) => {
  return (
    <table className={`full-width smaller text-alignment ${styles.vsTable}`}>
      <thead>
        <tr>
          <th></th>
          {comparisonTableP?.vsTableHeaders?.map((head, index) => (
            <th
              key={index}
              className={index === 1 ? styles.vsProfessional : ""}
            >
              {head.label}
              {index === 1 && <p className={styles.vsLabel}>Best Value!</p>}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {comparisonTableP?.vsTableBody?.map((row, index) => (
          <tr key={index}>
            <td>
              {row.description}
              {row.subcopy && (
                <em className={styles.vsDescriptionSubcopy}>{row.subcopy}</em>
              )}
            </td>
            <td>{row.essentialValue}</td>
            <td className={styles.vsTdProfessional}>{row.professionalValue}</td>
            <td dangerouslySetInnerHTML={{ __html: row.competitorValue }} />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VsTable;
